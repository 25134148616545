import PublicHeader from "@comp/public_header.vue";
import LeftMenu from "@comp/left_menu.vue";
export default {
    name: "user-manage",
    components: {
        LeftMenu,
        PublicHeader,
    },
    data() {
        return {
            menus: [
                {
                    id: "7001",
                    name: "用户管理",
                    routerName: "user",
                    className: "icon-yonghujiaose-01",
                },
                {
                    id: "7002",
                    name: "部门管理",
                    routerName: "department",
                    className: "icon-bumenguanli-01",
                },
                {
                    id: "7003",
                    name: "角色管理",
                    routerName: "role",
                    className: "icon-jiaoseguanli-01",
                },
                {
                    id: "7004",
                    name: "民居调查",
                    routerName: "survey",
                    className: "icon-jianceshujucaijipeizhi-01",
                },
                {
                    id: "7005",
                    name: "数据授权",
                    routerName: "data_impower",
                    // className: "icon-dataimpower",
                    bgPicture: "menu/ywgl_dataimpower",
                    bgPicture1: "menu/ywgl_dataimpower_active",
                },
                // {
                //     name: "设备信息",
                //     routerName: "equipment",
                //     className: "icon-shebeiguanli-01"
                // },
                {
                    id: "7006",
                    name: "日志管理",
                    routerName: "record",
                    className: "icon-rizhiguanli-01"
                }
            ],
            mainMenuIndex: "70",
        };
    },
    computed: {},
    mounted() { },
    methods: {},
};