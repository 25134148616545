<template>
    <div style="width:100%;height:100%;">
        <index1 v-if="system1"></index1>
        <index2 v-if="system2"></index2>
        <index3 v-if="system3"></index3>
    </div>
</template>
<script>
import index1 from "./comp/index1.vue";
import index2 from "./comp/index2.vue";
import index3 from "./comp/index3.vue";
import { mapGetters } from "vuex";
export default {
    name: "",
    data() {
        return {};
    },
    components: {
        index1,
        index2,
        index3,
    },
    computed: {
        ...mapGetters(["system"]),
        system1() {
            return this.system === "system1";
        },
        system2() {
            return this.system === "system2";
        },
        system3() {
            return this.system === "system3";
        },
    },
};
</script>
<style lang="scss" scoped></style>
