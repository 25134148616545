<template>
    <div id="monitoringcloud">
        <public-header class="public-header"></public-header>
        <div class="main-content flex">
            <left-menu
                class="left-menu"
                :menus="menus"
                :mainMenuIndex="mainMenuIndex"
                @changeMenu="changeMenu"
            ></left-menu>
            <div class="flex-1">
                <router-view class="main"></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import info_mixin from "./info_mixin";
export default {
    mixins: [info_mixin],
    name: "monitoringcloud",
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
#monitoringcloud {
    height: 100%;
    width: 100%;
    position: relative;
    .main-content {
        height: calc(100% - 77px);
        background: #eaece6;
        .main {
            height: calc(100% - 30px);
            background-color: #f7f9f4;
        }
    }
}
</style>
