<template>
    <div id="usermanage">
        <left-menu
            class="left-menu"
            :menus="menus"
            :mainMenuIndex="mainMenuIndex"
        ></left-menu>
        <div class="main-content">
            <public-header class="public-header"></public-header>
            <router-view class="main" :menus="menus"></router-view>
        </div>
    </div>
</template>
<script>
import info_mixin from "./info_mixin";
export default {
    mixins: [info_mixin],
    name: "usermanage",
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
<style lang="scss" scoped>
#usermanage {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    .main-content {
        flex: auto;
        display: flex;
        flex-flow: column nowrap;
        background-color: var(--bodyBgColor);
        .public-header {
            flex: none;
        }
        .main {
            height: 100%;
        }
    }
}
</style>
